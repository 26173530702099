import { BdsGrid, BdsInputChips, BdsLoadingSpinner, BdsSwitch, BdsTypo } from 'blip-ds/dist/blip-ds-react/components';
import SubPageHeader from 'components/SubPageHeader/SubPageHeader';
import { Analytics } from 'infra/adapters';
import ConfigurationsContainer from 'pages/Configurations/components/ConfigurationsContainer/ConfigurationsContainer';
import { useEffect, useMemo, useRef, useState } from 'react';
import ConfigurationsModuleTexts from '../../../utils/texts/ConfigurationsModuleTexts.json';
import Attendance from './Components/Attendance';
import { useOpeningHours } from './hooks/useOpeningHours';
import { Content, SpaceTextInput } from './styles';

const OpeningHours: React.FC = () => {
  const {
    initHook,
    twentyFourHours,
    setTwentyFourHours,
    sunday,
    setSunday,
    monday,
    setMonday,
    tuesday,
    setTuesday,
    wednesday,
    setWednesday,
    thursday,
    setThursday,
    friday,
    setFriday,
    saturday,
    setSaturday,
    holiday,
    setHoliday,
    saveOpeningHours,
    handleHolidaysChips,
    infoLoaded,
  } = useOpeningHours();

  const [isModified, setIsModified] = useState(false);
  const effectAlreadyRan = useRef(false);

  useEffect(() => {
    if (effectAlreadyRan.current || process.env.NODE_ENV !== 'development') {
      initHook();
    }

    return () => {
      effectAlreadyRan.current = true;
    };
  }, []);

  const chipsBuffer = useMemo(() => {
    return holiday.holidays ? holiday.holidays.split(',') : [];
  }, [holiday.holidays]);

  const handle24hToggle = () => {
    Analytics.Track(Analytics.events.ATTENDANCE_24H, { status: !twentyFourHours });

    setIsModified(!isModified);
    setTwentyFourHours(!twentyFourHours);
  };

  const handleHolidaysToggle = (toggleActivated: boolean) => {
    Analytics.Track(Analytics.events.ATTENDANCE_HOLIDAYS_ACTIVATE, { status: toggleActivated });
  };

  if (!infoLoaded)
    return (
      <BdsGrid justifyContent="center" alignItems="center" height="100%">
        <BdsLoadingSpinner />
      </BdsGrid>
    );

  return (
    <>
      <SubPageHeader
        showTestButton
        onSave={() => {
          setIsModified(false);
          saveOpeningHours();
        }}
        // buttonSaveDisabled={!isModified} <- This is commented to allow users to save current schedule to new Desk skill
      />

      <ConfigurationsContainer>
        <Content>
          Personalize os dias, horários e feriados que o seu atendimento humano estará disponível.
          <SpaceTextInput>
            <BdsGrid gap="2">
              <BdsSwitch
                refer=""
                name="twentyFourHours"
                checked={twentyFourHours}
                onBdsChange={handle24hToggle}
                data-testid="switchTwentyFourHours"
              />
              <BdsTypo className="typo" variant="fs-14">
                {ConfigurationsModuleTexts.schedules.twentyFourHours}
              </BdsTypo>
            </BdsGrid>

            {!twentyFourHours && (
              <>
                <Attendance
                  lastInput={false}
                  name="Segunda"
                  text={ConfigurationsModuleTexts.schedules.days.monday}
                  setDays={setMonday}
                  setModified={setIsModified}
                  day={monday}
                />
                <Attendance
                  lastInput={false}
                  name="Terça"
                  text={ConfigurationsModuleTexts.schedules.days.tuesday}
                  setDays={setTuesday}
                  setModified={setIsModified}
                  day={tuesday}
                />
                <Attendance
                  lastInput={false}
                  name="Quarta"
                  text={ConfigurationsModuleTexts.schedules.days.wednesday}
                  setDays={setWednesday}
                  setModified={setIsModified}
                  day={wednesday}
                />
                <Attendance
                  lastInput={false}
                  name="Quinta"
                  text={ConfigurationsModuleTexts.schedules.days.thursday}
                  setDays={setThursday}
                  setModified={setIsModified}
                  day={thursday}
                />
                <Attendance
                  lastInput={false}
                  name="Sexta"
                  text={ConfigurationsModuleTexts.schedules.days.friday}
                  setDays={setFriday}
                  setModified={setIsModified}
                  day={friday}
                />
                <Attendance
                  lastInput={false}
                  name="Sábado"
                  text={ConfigurationsModuleTexts.schedules.days.saturday}
                  setDays={setSaturday}
                  setModified={setIsModified}
                  day={saturday}
                />
                <Attendance
                  lastInput={false}
                  name="Domingo"
                  text={ConfigurationsModuleTexts.schedules.days.sunday}
                  setDays={setSunday}
                  setModified={setIsModified}
                  day={sunday}
                />

                <Attendance
                  name="Feriados"
                  text={ConfigurationsModuleTexts.schedules.days.holiday}
                  lastInput={false}
                  setDays={setHoliday}
                  setModified={setIsModified}
                  day={holiday}
                  togglePressed={handleHolidaysToggle}
                />
                <BdsGrid padding="b-2">
                  <BdsInputChips
                    id="input-chips"
                    disabled={!holiday.active}
                    duplicated={false}
                    chips={chipsBuffer}
                    errorMessage={ConfigurationsModuleTexts.schedules.error.requiredHolidays}
                    onBdsChangeChips={handleHolidaysChips}
                    onBdsInputChipsFocus={() => setIsModified(true)}
                    data-testid="holidayDays"
                  />
                </BdsGrid>
              </>
            )}
          </SpaceTextInput>
        </Content>
      </ConfigurationsContainer>
    </>
  );
};

export default OpeningHours;
