import { Day } from 'objects/types/humamService/Day';
import { Turn } from 'objects/types/humamService/Turn';

export const GetWorkSchedule = (days: Record<string, Day>) => {
  const workSchedule: {
    num: number | undefined;
    name: string;
    portugueseName: string | undefined;
    workTime: Turn[];
  }[] = [];
  const daysPortugueseNames: string[] = [];

  Object.keys(days).forEach(day => {
    const invalidDay = days[day].turns.find(turn => turn.end === '' || turn.start === '') || days[day].active === false;
    if (!invalidDay) {
      let portugueseName;
      let num;
      switch (day) {
        case 'Sunday':
          portugueseName = 'Domingo';
          num = 0;
          daysPortugueseNames.push(portugueseName);
          break;
        case 'Monday':
          portugueseName = 'Segunda';
          num = 1;
          daysPortugueseNames.push(portugueseName);
          break;
        case 'Tuesday':
          portugueseName = 'Terça';
          num = 2;
          daysPortugueseNames.push(portugueseName);
          break;
        case 'Wednesday':
          portugueseName = 'Quarta';
          num = 3;
          daysPortugueseNames.push(portugueseName);
          break;
        case 'Thursday':
          portugueseName = 'Quinta';
          num = 4;
          daysPortugueseNames.push(portugueseName);
          break;
        case 'Friday':
          portugueseName = 'Sexta';
          num = 5;
          daysPortugueseNames.push(portugueseName);
          break;
        case 'Saturday':
          portugueseName = 'Sábado';
          num = 6;
          daysPortugueseNames.push(portugueseName);
          break;
        case 'Holidays':
          portugueseName = 'Feriados';
          num = 7;
          daysPortugueseNames.push(portugueseName);
          break;
      }

      workSchedule.push({
        num,
        name: day,
        portugueseName,
        workTime: days[day].turns,
      });
    }
  });

  return {
    workSchedule,
    daysPortugueseNames,
  };
};

export const GetEarlierAndLaterValues = (days: Day) => {
  const daysHoursEnd = days.turns.filter(turn => turn.end !== '').map(turn => turn.end);
  const daysHoursStart = days.turns.filter(turn => turn.start !== '').map(turn => turn.start);

  const daysEndSorted = daysHoursEnd.sort().reverse();
  const daysStartSorted = daysHoursStart.sort();

  return {
    earlier: daysStartSorted[0],
    later: daysEndSorted[0],
  };
};
