export const TOAST_SUCCESS = 'Sucesso';
export const TOAST_SUCCESS_MESSAGE = 'Todas as alterações foram salvas com sucesso!';
export const TOAST_SUCCESS_MESSAGE_INVITE_MEMBER = 'Membro da equipe convidado com sucesso!';
export const TOAST_SUCCESS_MESSAGE_REMOVE_MEMBER = 'Membro da equipe removido com sucesso!';
export const TOAST_FAILURE = 'Falha';
export const TOAST_FAILURE_MESSAGE = 'Houve uma falha ao salvar as alterações.';
export const TOAST_FAILURE_QUEUE_INVALID = 'Problema no preenchimento de campo';
export const TOAST_FAILURE_QUEUE_INVALID_MESSAGE =
  'Um ou mais campos estão em branco ou não foram preenchidos corretamente.';
export const TOAST_FAILURE_IDENTICAL_QUEUE_NAMES = 'Nomes da equipes não podem ser idênticos.';
export const TOAST_CONTACT_SUPPORT = 'Entre em contato com o suporte.';
export const TOAST_FAILURE_REMOVE_REQUIRED_QUEUE = 'É necessário que haja ao menos uma equipe cadastrada.';
export const TOAST_DUPLICATE_MEMBER_TITLE = 'Membro já cadastrado';
export const TOAST_DUPLICATE_MEMBER_DESCRIPTION = 'Um membro já está registrado com este e-mail.';
