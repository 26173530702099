import Switch from 'components/Switch';
import { Day } from 'objects/types/humamService/Day';
import { Dispatch, SetStateAction } from 'react';
import TurnHours from '../TurnHours';
import { MainContainer, TurnContainer } from './styles';

type AttendanceProps = {
  text: string;
  setDays: Dispatch<SetStateAction<Day>>;
  day: Day;
  name: string;
  lastInput: boolean;
  setModified: Dispatch<SetStateAction<boolean>>;
  togglePressed?: (event: any) => void;
};

export const Attendance = ({ text, setDays, name, lastInput, day, setModified, togglePressed }: AttendanceProps) => {
  const handleInputsDisabled = async (event: Event) => {
    setModified(true);
    const input = event.currentTarget as HTMLBdsSwitchElement;
    const value = await input?.getValue();
    togglePressed?.(value);

    setDays((current: Day) => {
      return {
        ...current,
        active: value,
        turns: [{ start: day.turns[0].start, end: day.turns[0].end }],
      };
    });
  };

  const handleAddTurn = () => {
    setModified(true);
    setDays(current => {
      return {
        ...current,
        turns: [...day.turns, { end: '', start: '' }],
      };
    });
  };

  const handleRemoveTurn = (i: number) => {
    setModified(true);
    const turns = [...day.turns];
    turns.splice(i, 1);

    setDays(current => {
      return { ...current, turns };
    });
  };

  return (
    <MainContainer $lastInput={lastInput}>
      <Switch
        checked={day.active}
        onChange={handleInputsDisabled}
        name={name}
        text={text}
        isEmojiSwitch={false}
        dataTestId={`switchDay${name}`}
      />

      <TurnContainer>
        {day.turns.map((turn, i) => (
          <TurnHours
            handleAddTurn={handleAddTurn}
            handleRemoveTurn={handleRemoveTurn}
            turns={day.turns}
            turn={turn}
            isDisabled={!day.active}
            setDays={setDays}
            setModified={setModified}
            key={turn.start + turn.end}
            index={i}
            nameDay={`${name}${i}`}
          />
        ))}
      </TurnContainer>
    </MainContainer>
  );
};
