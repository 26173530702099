import { BdsIcon, BdsTooltip } from 'blip-ds/dist/blip-ds-react';
import { SelectHour } from 'components/SelectHour/SelectHour';
import { Day } from 'objects/types/humamService/Day';
import { Turn } from 'objects/types/humamService/Turn';
import { Dispatch, SetStateAction } from 'react';
import ConfigurationsModuleTexts from '../../../../../utils/texts/ConfigurationsModuleTexts.json';
import { HoursContainer, MainContainer } from './styles';

interface TurnHoursProps {
  index: number;
  turn: Turn;
  turns: Turn[];
  setDays: Dispatch<SetStateAction<Day>>;
  setModified: Dispatch<SetStateAction<boolean>>;
  isDisabled: boolean;
  handleAddTurn: () => void;
  handleRemoveTurn: (i: number) => void;
  nameDay?: string;
}

export const TurnHours = ({
  index,
  turns,
  isDisabled,
  handleAddTurn,
  handleRemoveTurn,
  turn,
  setModified,
  nameDay,
}: TurnHoursProps) => {
  const shouldShowRemoveIcon = turns.length - 1 === index && index > 0;
  const shouldShowTooltip = turns.length - 1 === index && turns.length < 3;

  const updatePeriod = (isStart: boolean, period: string) => {
    if (isStart) {
      turns[index].start = period;
    } else {
      turns[index].end = period;
    }

    setModified(true);
  };

  return (
    <MainContainer $index={index} $showRemoveIcon={shouldShowRemoveIcon}>
      <HoursContainer>
        <SelectHour
          value={isDisabled ? '' : turn.start}
          setAttendance={period => updatePeriod(true, period)}
          disabled={isDisabled}
          text={ConfigurationsModuleTexts.schedules.times.start}
          end={0}
          dataTestId={`testStart${nameDay}`}
        />

        <SelectHour
          value={isDisabled ? '' : turn.end}
          setAttendance={period => updatePeriod(false, period)}
          disabled={isDisabled}
          text={ConfigurationsModuleTexts.schedules.times.end}
          end={1}
          dataTestId={`testEnd${nameDay}`}
        />
      </HoursContainer>

      {shouldShowTooltip && (
        <BdsTooltip
          className="tooltip"
          position="left-center"
          tooltip-text={ConfigurationsModuleTexts.schedules.button.add}
        >
          <BdsIcon
            className="icon"
            name="add"
            size="medium"
            onClick={handleAddTurn}
            data-testid={`addTurn${nameDay}`}
          />
        </BdsTooltip>
      )}

      {shouldShowRemoveIcon && (
        <BdsTooltip
          className="tooltip"
          class="icon-remove"
          position="left-center"
          tooltip-text={ConfigurationsModuleTexts.schedules.button.remove}
        >
          <BdsIcon
            className="icon"
            name="delete"
            size="medium"
            onClick={() => handleRemoveTurn(index)}
            data-testid={`removeTurn${nameDay}`}
          />
        </BdsTooltip>
      )}
    </MainContainer>
  );
};
