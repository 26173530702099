import moment from 'moment';
import { Day } from 'objects/types/humamService/Day';

export const ValidateHours = (days: Record<string, Day>) => {
  let isValid = true;
  Object.values(days).forEach(day => {
    day.turns.forEach(turn => {
      if (day.active) {
        const startTime = moment(turn.start, 'hh:mm');
        const endTime = moment(turn.end, 'hh:mm');

        if (
          !ValidateHour(turn.start) ||
          !ValidateHour(turn.end) ||
          !startTime.isValid() ||
          !endTime.isValid() ||
          startTime.isSameOrAfter(endTime) ||
          day.holidays === ''
        ) {
          isValid = false;
        }
      }
    });
  });

  return isValid;
};

export const ValidateHour = (hour: string) => {
  const hoursRegex = /^([01]\d|2[0-3]):([0-5]\d)$/g;
  return hour.match(hoursRegex) !== null;
};

export const GetTurnHoursMessage = (days: Record<string, Day>) => {
  let msg = '';

  for (const [day, value] of Object.entries(days)) {
    const setMsg = (dayString: string) => {
      msg += `${dayString}:\n`;

      value.turns.forEach(turn => {
        msg += `${turn.start} - ${turn.end}\n`;
      });

      msg += '\n';
    };

    const allValid = value.turns.every(turn => turn.end !== '' && turn.start !== '' && value.active);

    if (allValid) {
      switch (day) {
        case 'Sunday':
          setMsg('Domingo');
          break;
        case 'Monday':
          setMsg('Segunda-feira');
          break;
        case 'Tuesday':
          setMsg('Terça-feira');
          break;
        case 'Wednesday':
          setMsg('Quarta-feira');
          break;
        case 'Thursday':
          setMsg('Quinta-feira');
          break;
        case 'Friday':
          setMsg('Sexta-feira');
          break;
        case 'Saturday':
          setMsg('Sábado');
          break;
      }
    }
  }

  return msg;
};
