import {
  BdsButton,
  BdsGrid,
  BdsIcon,
  BdsInput,
  BdsSelect,
  BdsSelectOption,
  BdsTooltip,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react';
import { OfferBanner } from 'components/OfferBanner/OfferBanner';
import SubPageHeader from 'components/SubPageHeader/SubPageHeader';
import { GetAccountSettings } from 'configurations/Account';
import { ATTENDANT_MEMBER_ID } from 'configurations/Environment';
import { ANALYTICS } from 'constants/Analytics';
import { PROFILE } from 'constants/Profiles';
import { EMAIL_REGEX } from 'constants/Regex';
import { SIGNATURE_ROUTE } from 'constants/Routes';
import { SubModuleNames } from 'constants/SubModuleNames';
import {
  TOAST_DUPLICATE_MEMBER_DESCRIPTION,
  TOAST_DUPLICATE_MEMBER_TITLE,
  TOAST_FAILURE_MESSAGE,
  TOAST_SUCCESS,
  TOAST_SUCCESS_MESSAGE_INVITE_MEMBER,
  TOAST_SUCCESS_MESSAGE_REMOVE_MEMBER,
} from 'constants/ToastMessages';
import { Analytics } from 'infra/adapters';
import { PlanTypeEnum } from 'objects/types/BlipGoApi';
import { TrackingProperties } from 'objects/types/TrackingProperties';
import { UserTenant } from 'objects/types/UserTenant';
import { useAuth } from 'oidc-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCustomerService } from 'redux/slices/CustomerServiceSlice/customerServiceSlice';
import { addAttendant, removeAttendant } from 'redux/slices/CustomerServiceSlice/thunkMiddleware';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { addAdmin, removeAdmin, useMembers } from 'redux/slices/MembersSlice/membersSlice';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';
import { AppDispatch } from 'redux/store';
import { AddUsersTenant, createMember, disassociateMember } from 'services/PackService';
import { createToastError, createToastInfo, createToastSuccess } from 'services/Toats';
import { encodeAttendantBlipEmail, encodeToDeleteAttendantBlipEmail } from 'utils/BlipEncodeDecode';
import { GetBotAuthorization } from 'utils/Installation';
import ConfigurationsModuleTexts from 'utils/texts/ConfigurationsModuleTexts.json';
import ConfigurationsContainer from '../components/ConfigurationsContainer/ConfigurationsContainer';
import { MemberItem } from './components/MemberItem/MemberItem';

export const Members = () => {
  const MAX_ATTENDANTS_BASIC = 4;

  const { admins } = useSelector(useMembers);
  const [email, setEmail] = useState('');
  const [profile, setProfile] = useState('0');
  const [emailError, setEmailError] = useState(false);
  const [enabledSubmmit, setEnabledSubmmit] = useState(false);
  const [isSubmitting, setIsSubmtting] = useState(false);
  const auth = useAuth();
  const token = GetAccountSettings(auth).token;
  const dispatch = useDispatch<AppDispatch>();
  const { attendants } = useSelector(useCustomerService);
  const { desk, ownerIdentity, applicationId, tenant, installationId } = useSelector(useInstallation);
  const key = GetBotAuthorization(desk.shortName, desk.accessKey);
  const [showTooltip, setshowTooltip] = useState(attendants.length >= MAX_ATTENDANTS_BASIC);
  const { planType } = useSelector(useProject);

  const navigate = useNavigate();

  useEffect(() => {
    if (planType !== PlanTypeEnum.Pro) {
      if (attendants.length >= MAX_ATTENDANTS_BASIC && profile === ATTENDANT_MEMBER_ID) {
        setshowTooltip(true);
        setEnabledSubmmit(false);
      } else {
        setshowTooltip(false);
        setEnabledSubmmit(email.length > 0 && profile !== '0');
      }
    } else {
      setshowTooltip(false);
      setEnabledSubmmit(email.length > 0 && profile !== '0');
    }
  }, [email, profile, attendants]);

  const handleAddMember = async () => {
    if (!validateEmail()) {
      setEmailError(true);
      return;
    }

    if (isAlreadyAMember(email)) {
      clearFields();
      createToastInfo(TOAST_DUPLICATE_MEMBER_TITLE, TOAST_DUPLICATE_MEMBER_DESCRIPTION);
      return;
    }

    try {
      setIsSubmtting(true);
      if (profile === PROFILE.admin.id) {
        await createAdmin();
      } else {
        await createAttendant();
      }

      Analytics.Track(ANALYTICS.INVITE_MEMBER, {
        userPermission: profile === PROFILE.admin.id ? PROFILE.admin.name : PROFILE.attendant.name,
        numAgents: attendants.length + 1,
      });

      createToastSuccess(TOAST_SUCCESS, TOAST_SUCCESS_MESSAGE_INVITE_MEMBER);
    } catch {
    } finally {
      clearFields();
      setIsSubmtting(false);
    }
  };

  const createAdmin = async () => {
    const member = await createMember(email, installationId, profile, token);
    if (member?.length) {
      member[0].profileName = PROFILE.admin.name;
      dispatch(addAdmin(member[0]));
    }
  };

  const addUsersTenant = async () => {
    try {
      const emails = [];
      attendants.forEach(item => {
        emails.push(item.email);
      });

      emails.push(email);

      const userTenant: UserTenant = {
        ownerEmail: ownerIdentity,
        packId: Number(applicationId),
        tenantId: tenant.id,
        usersEmails: emails,
      };
      await AddUsersTenant(userTenant, token);
    } catch (ex) {
      const error = ex as Error;
      return Promise.reject(error);
    }
  };

  const createAttendant = async () => {
    const trackingProperties = {} as TrackingProperties;

    try {
      const newAttendantEncoded = encodeAttendantBlipEmail(email);

      await addUsersTenant();

      await dispatch(addAttendant({ token: key, email: newAttendantEncoded })).unwrap();
    } catch (ex: any) {
      createToastError(TOAST_FAILURE_MESSAGE, ex);

      trackingProperties.status = 'falha';
      trackingProperties.error = ex.message;
      trackingProperties.numAgents = attendants.length;
      trackingProperties.screenName = SubModuleNames.members;
      trackingProperties.warningModal = false;
      Analytics.Track(ANALYTICS.CLICK_SAVE_BUTTON, trackingProperties);
    }
  };

  const validateEmail = () => {
    return EMAIL_REGEX.test(email);
  };

  const handleEmailTyping = (event: Event) => {
    const { value } = event.target as HTMLBdsInputElement;
    setEmail(value ?? '');
    setEmailError(false);
  };

  const handleDeleteMember = async (email: string, profileId: string, memberId?: number) => {
    try {
      if (profileId === PROFILE.admin.id && memberId) {
        await disassociateMember(memberId, installationId, token);
        dispatch(removeAdmin(memberId));
      } else {
        const propertyTrack = { screenName: SubModuleNames.members, type: 'Integrante da equipe' };
        Analytics.Track(ANALYTICS.CLICK_DELETE_ITEM_ICON, propertyTrack);

        const attendantEncoded = encodeToDeleteAttendantBlipEmail(email);

        await dispatch(removeAttendant({ token: key, email: attendantEncoded })).unwrap();
      }
      createToastSuccess(TOAST_SUCCESS, TOAST_SUCCESS_MESSAGE_REMOVE_MEMBER);
    } catch (ex: any) {
      createToastError(TOAST_FAILURE_MESSAGE, ex);
    }
  };

  const handleProfileChange = (ev: CustomEvent) => {
    setProfile(ev?.detail?.value);
  };

  const isAlreadyAMember = (email: string) => {
    return attendants.some(attendant => attendant.email === email) || admins.some(admin => admin.email === email);
  };

  const clearFields = () => {
    setEmail('');
    setProfile('0');
  };

  const redirectToSignaturePlan = () => {
    Analytics.Track(Analytics.events.OFFER_BANNER_ATTENDANTS);
    navigate(SIGNATURE_ROUTE);
  };

  return (
    <>
      <SubPageHeader showTestButton />

      <ConfigurationsContainer>
        <BdsGrid gap="4" direction="column" justifyContent="center">
          <BdsGrid gap="2" direction="column">
            <BdsTypo>Convide seus membros de equipe para que comecem a utilizar o Blip Go!</BdsTypo>
            <BdsGrid gap="1">
              <BdsInput
                label="E-mail do membro da equipe"
                required
                type="text"
                value={email}
                data-testid="memberEmailInput"
                onBdsInput={handleEmailTyping}
                errorMessage={ConfigurationsModuleTexts.attendants.requiredField}
                requiredErrorMessage={ConfigurationsModuleTexts.attendants.requiredField}
                danger={emailError}
                style={{ width: '23.125rem' }}
              ></BdsInput>
              <BdsSelect
                value={profile}
                label="Permissão"
                placeholder="Selecione"
                onBdsChange={handleProfileChange}
                data-testid="profileSelect"
                style={{ width: '12.5rem' }}
              >
                <BdsSelectOption titleText="Administrador" value={PROFILE.admin.id}>
                  <BdsGrid gap="2" alignItems="center">
                    <BdsIcon name="user-favorite"></BdsIcon>
                    <BdsTypo variant="fs-14">Poderá utilizar todas as funcionalidades</BdsTypo>
                  </BdsGrid>
                </BdsSelectOption>
                <BdsSelectOption titleText="Atendente" value={PROFILE.attendant.id} data-testid="attendantOption">
                  <BdsGrid gap="2" alignItems="center">
                    <BdsIcon name="agent"></BdsIcon>
                    <BdsTypo variant="fs-14">Poderá ler e responder conversas</BdsTypo>
                  </BdsGrid>
                </BdsSelectOption>
              </BdsSelect>
              <BdsTooltip
                position="top-center"
                tooltip-text="Limite de atendentes atingido para o plano atual."
                disabled={!showTooltip}
              >
                <BdsButton
                  icon="checkball"
                  onBdsClick={handleAddMember}
                  disabled={!enabledSubmmit || isSubmitting}
                  bdsLoading={isSubmitting}
                  data-testid="inviteMemberBtn"
                >
                  Convidar
                </BdsButton>
              </BdsTooltip>
            </BdsGrid>
          </BdsGrid>
          {attendants.length >= MAX_ATTENDANTS_BASIC && planType !== PlanTypeEnum.Pro && (
            <OfferBanner
              title="Mais atendentes com o plano Pro!"
              textContent="Desbloqueie recursos exclusivos, aumente o limite de atendentes, equipes e conversas mensais."
              textButton="Quero assinar o plano Pro"
              actionButton={redirectToSignaturePlan}
            />
          )}
          <BdsGrid gap="1" direction="column">
            <BdsTypo bold="bold">Membros da equipe</BdsTypo>
            {admins?.map(admin => {
              return (
                <MemberItem
                  key={admin.email}
                  email={admin.email}
                  profileName={admin.profileName}
                  buttonDeleteEnabled={admin.email !== auth.userData?.profile?.email}
                  onDeleteEvent={() => handleDeleteMember(admin.email, PROFILE.admin.id, admin.id)}
                />
              );
            })}
            {attendants.map(attendant => {
              return (
                <MemberItem
                  key={attendant.email}
                  email={attendant.email}
                  profileName={PROFILE.attendant.name}
                  buttonDeleteEnabled={true}
                  onDeleteEvent={() => handleDeleteMember(attendant.email, PROFILE.attendant.id)}
                />
              );
            })}
          </BdsGrid>
        </BdsGrid>
      </ConfigurationsContainer>
    </>
  );
};
