import { BdsSwitch, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { MainContainer } from './styles';

type SwitchProps = {
  name: string;
  text: string;
  onChange: (event: any) => void;
  checked?: boolean;
  isEmojiSwitch: boolean;
  dataTestId?: string;
};

export const Switch = ({ name, text, onChange, checked, isEmojiSwitch, dataTestId }: SwitchProps) => (
  <MainContainer $isEmojiSwitch={isEmojiSwitch}>
    <BdsSwitch name={name} checked={checked} refer="" onBdsChange={onChange} data-testid={dataTestId} />

    <BdsTypo className="typo" variant="fs-14">
      {text}
    </BdsTypo>
  </MainContainer>
);
