import { ANALYTICS } from 'constants/Analytics';
import { RESOURCES } from 'constants/ResourcesNames';
import { TOAST_FAILURE, TOAST_FAILURE_MESSAGE, TOAST_SUCCESS, TOAST_SUCCESS_MESSAGE } from 'constants/ToastMessages';
import { Analytics, Logger } from 'infra/adapters';
import { Schedule } from 'objects/types/Schedule';
import { Day } from 'objects/types/humamService/Day';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';
import {
  updateDays,
  updateHolidayAttendance,
  updateHolidays,
  updateOpenHour,
  updateWorkDays,
  updateWorkSchedule,
  updateWorkdayWithSaturday,
  useResource,
} from 'redux/slices/ResourceSlices/resourceSlice';
import { SaveAttendanceSchedule } from 'services/BlipGoApiService';
import { GetAttendanceRegistry, UpdateIsFulltimeAttendance } from 'services/BlipGoApiService';
import { setSpecificResource, setSpecificsResources } from 'services/CommandsService';
import { createToastError, createToastSuccess } from 'services/Toats';
import { GetTurnHoursMessage, ValidateHours } from 'utils/Hour';
import { GetBotAuthorization } from 'utils/Installation';
import { formatLogMessage } from 'utils/LogMessage';
import { GetEarlierAndLaterValues, GetWorkSchedule } from 'utils/WorkSchedule';
import ConfigurationsModuleTexts from '../../../../utils/texts/ConfigurationsModuleTexts.json';

type WorkSchedule = {
  num: number;
  name: string;
  portugueseName: string;
  workTime: [
    {
      start: string;
      end: string;
    },
  ];
};

const className = 'useOpeningHours.tsx';

export const useOpeningHours = () => {
  const dispatch = useDispatch();
  const { workSchedule, holidayAttendance, holidays, openHour, days } = useSelector(useResource);
  const { router, tenant, desk } = useSelector(useInstallation);
  const { activeSubPage } = useSelector(useProject);
  const workScheduleParsed = JSON.parse(workSchedule) as WorkSchedule[];

  const TEXT_TYPE = 'text/plain';
  const RETURN_SUCCESS_COMMANDS = 'success';
  const key = GetBotAuthorization(router.shortName, router.accessKey);

  const [infoLoaded, setInfoLoaded] = useState(false);

  const [twentyFourHours, setTwentyFourHours] = useState(openHour !== 'false');

  const [sunday, setSunday] = useState<Day>({
    turns: [{ end: days.sunday.end, start: days.sunday.start }],
    active: false,
  });

  const [monday, setMonday] = useState<Day>({
    turns: [{ end: '', start: '' }],
    active: false,
  });

  const [tuesday, setTuesday] = useState<Day>({
    turns: [{ end: '', start: '' }],
    active: false,
  });

  const [wednesday, setWednesday] = useState<Day>({
    turns: [{ end: '', start: '' }],
    active: false,
  });

  const [thursday, setThursday] = useState<Day>({
    turns: [{ end: '', start: '' }],
    active: false,
  });

  const [friday, setFriday] = useState<Day>({
    turns: [{ end: '', start: '' }],
    active: false,
  });

  const [saturday, setSaturday] = useState<Day>({
    turns: [{ end: days.saturday.end, start: days.saturday.start }],
    active: false,
  });

  const [holiday, setHoliday] = useState<Day>({
    turns: [{ end: days.holiday.end, start: days.holiday.start }],
    active: false,
    holidays: holidays,
  });

  const initHook = () => {
    recoverWorkingDayAndTime();
  };

  const saveDeskSchedule = async (days: Record<string, Day>) => {
    try {
      const schedule: Schedule[] = [];
      Object.keys(days).forEach(day => {
        days[day].turns.forEach(turn => {
          if (day !== 'Holidays' && days[day].active) {
            const scheduleItem: Schedule = {
              dayOfWeek: day,
              endTime: turn.end,
              startTime: turn.start,
            };
            schedule.push(scheduleItem);
          }
        });
      });
      const holidays = days['Holidays'];
      await SaveAttendanceSchedule(tenant.id, desk.shortName, schedule, holidays.holidays?.split(',') ?? []);
      await UpdateIsFulltimeAttendance(router.shortName, twentyFourHours);
    } catch (error) {
      throw new Error('[Desk skill] Falha ao salvar horário de atendimento');
    }
  };

  const saveOpeningHours = async () => {
    const methodName = 'saveOpeningHours';

    try {
      setInfoLoaded(false);
      if (twentyFourHours) {
        await setSpecificResource(key, {
          name: RESOURCES.TWENTY_FOUR_HOURS,
          value: twentyFourHours.toString(),
          type: TEXT_TYPE,
        });
        await UpdateIsFulltimeAttendance(router.shortName, twentyFourHours);
        dispatch(updateOpenHour(twentyFourHours.toString()));
      } else {
        const days: Record<string, Day> = {
          Sunday: sunday,
          Monday: monday,
          Tuesday: tuesday,
          Wednesday: wednesday,
          Thursday: thursday,
          Friday: friday,
          Saturday: saturday,
          Holidays: holiday,
        };

        if (ValidateHours(days)) {
          const { workSchedule, daysPortugueseNames } = GetWorkSchedule(days);

          const { earlier: saturdayStartEarlier, later: saturdayEndLater } = GetEarlierAndLaterValues(days['Saturday']);
          const { earlier: sundayStartEarlier, later: sundayEndLater } = GetEarlierAndLaterValues(days['Sunday']);
          const { earlier: holidayStartEarlier, later: holidayEndLater } = GetEarlierAndLaterValues(days['Holidays']);
          const openingHoursMessage = GetTurnHoursMessage(days);
          dispatch(updateWorkDays(daysPortugueseNames.toString()));
          dispatch(
            updateDays({
              holiday: {
                end: holidayEndLater,
                start: holidayStartEarlier,
              },
              saturday: {
                end: saturdayEndLater,
                start: saturdayStartEarlier,
              },
              sunday: {
                end: sundayEndLater,
                start: sundayStartEarlier,
              },
            }),
          );
          holiday.holidays && dispatch(updateHolidays(holiday.holidays));
          dispatch(updateHolidayAttendance(JSON.stringify(holiday.active)));
          dispatch(updateWorkdayWithSaturday(openingHoursMessage));
          dispatch(updateOpenHour(twentyFourHours.toString()));
          dispatch(updateWorkSchedule(JSON.stringify(workSchedule)));
          await saveDeskSchedule(days);
          await saveResourcesBot(
            days,
            saturdayStartEarlier,
            saturdayEndLater,
            sundayStartEarlier,
            sundayEndLater,
            holidayStartEarlier,
            holidayEndLater,
          );
        } else {
          createToastError(TOAST_FAILURE_MESSAGE, 'Formato de horário inválido');
          Analytics.Track(ANALYTICS.CLICK_SAVE_BUTTON, {
            status: 'falha',
            error: 'Formato de horário inválido',
            screenName: activeSubPage.title,
            service24h: twentyFourHours,
            warningModal: false,
          });
          return;
        }
      }
      Analytics.Track(ANALYTICS.CLICK_SAVE_BUTTON, {
        status: 'sucesso',
        screenName: activeSubPage.title,
        service24h: twentyFourHours,
        warningModal: false,
      });
      createToastSuccess(TOAST_SUCCESS, TOAST_SUCCESS_MESSAGE);
    } catch (ex: any) {
      Analytics.Track(ANALYTICS.CLICK_SAVE_BUTTON, {
        status: 'falha',
        error: ex.message,
        screenName: activeSubPage.title,
        service24h: twentyFourHours,
        warningModal: false,
      });

      createToastError(TOAST_FAILURE, TOAST_FAILURE_MESSAGE);
      Logger.Error(formatLogMessage(ex, 'There was a failure to save changes.'), {
        methodName,
        className,
      });
    } finally {
      setInfoLoaded(true);
    }
  };

  const saveResourcesBot = async (
    days: Record<string, Day>,
    saturdayStartEarlier: string,
    saturdayEndLater: string,
    sundayStartEarlier: string,
    sundayEndLater: string,
    holidayStartEarlier: string,
    holidayEndLater: string,
  ) => {
    const methodName = 'saveResourcesBot';

    const { workSchedule, daysPortugueseNames } = GetWorkSchedule(days);
    const openingHoursMessage = GetTurnHoursMessage(days);

    const responses = await setSpecificsResources(key, [
      {
        name: RESOURCES.WORK_SCHEDULE,
        value: JSON.stringify(workSchedule),
        type: TEXT_TYPE,
      },
      {
        name: RESOURCES.WORK_DAYS,
        value: daysPortugueseNames.toString() ?? '',
        type: TEXT_TYPE,
      },
      {
        name: RESOURCES.DAYS.SUNDAY.START,
        value: sundayStartEarlier,
        type: TEXT_TYPE,
      },
      {
        name: RESOURCES.DAYS.SUNDAY.END,
        value: sundayEndLater,
        type: TEXT_TYPE,
      },
      {
        name: RESOURCES.DAYS.SATURDAY.START,
        value: saturdayStartEarlier,
        type: TEXT_TYPE,
      },
      {
        name: RESOURCES.DAYS.SATURDAY.END,
        value: saturdayEndLater,
        type: TEXT_TYPE,
      },
      {
        name: RESOURCES.DAYS.HOLIDAY.START,
        value: holidayStartEarlier,
        type: TEXT_TYPE,
      },
      {
        name: RESOURCES.DAYS.HOLIDAY.END,
        value: holidayEndLater,
        type: TEXT_TYPE,
      },
      {
        name: RESOURCES.HOLIDAYS,
        value: holiday.holidays ?? '',
        type: TEXT_TYPE,
      },
      {
        name: RESOURCES.HOLIDAY_ATTENDANCE,
        value: JSON.stringify(holiday.active) ?? '',
        type: TEXT_TYPE,
      },
      {
        name: RESOURCES.TWENTY_FOUR_HOURS,
        value: twentyFourHours.toString() ?? '',
        type: TEXT_TYPE,
      },
      {
        name: RESOURCES.MESSAGES.WORKDAY_WITH_SATURDAY,
        value: openingHoursMessage ?? '',
        type: TEXT_TYPE,
      },
    ]);

    for (const res of responses) {
      if (res.status !== RETURN_SUCCESS_COMMANDS) {
        Analytics.Track(ANALYTICS.CLICK_SAVE_BUTTON, {
          status: 'falha',
          error: res.reason,
          screenName: activeSubPage.title,
          service24h: twentyFourHours,
          warningModal: false,
        });

        createToastError(TOAST_FAILURE, TOAST_FAILURE_MESSAGE);
        Logger.Error(`There was a failure to save changes. Error: ${res}`, {
          methodName,
          className,
        });
        return;
      }
    }
  };

  const recoverWorkingDayAndTime = async () => {
    workScheduleParsed.forEach(day => {
      if (day.portugueseName == ConfigurationsModuleTexts.schedules.days.sunday) {
        setSunday(sunday => ({ ...sunday, active: true }));
      }
      if (day.portugueseName == ConfigurationsModuleTexts.schedules.days.monday) {
        setMonday(monday => ({ ...monday, turns: day.workTime, active: true }));
      }
      if (day.portugueseName == ConfigurationsModuleTexts.schedules.days.tuesday) {
        setTuesday(tuesday => ({ ...tuesday, turns: day.workTime, active: true }));
      }
      if (day.portugueseName == ConfigurationsModuleTexts.schedules.days.wednesday) {
        setWednesday(wednesday => ({ ...wednesday, turns: day.workTime, active: true }));
      }
      if (day.portugueseName == ConfigurationsModuleTexts.schedules.days.thursday) {
        setThursday(thursday => ({ ...thursday, turns: day.workTime, active: true }));
      }
      if (day.portugueseName == ConfigurationsModuleTexts.schedules.days.friday) {
        setFriday(friday => ({ ...friday, turns: day.workTime, active: true }));
      }
      if (day.portugueseName == ConfigurationsModuleTexts.schedules.days.saturday) {
        setSaturday(saturday => ({ ...saturday, active: true }));
      }
    });
    const attendanceRegistry = await GetAttendanceRegistry(router.shortName, openHour !== 'false');
    setTwentyFourHours(attendanceRegistry.isFulltime);
    setHoliday(holiday => ({
      ...holiday,
      active: holidayAttendance === 'true',
      holidays: holiday.holidays,
    }));
    setInfoLoaded(true);
  };

  const handleHolidaysChips = async (ev: Event) => {
    const input = ev.currentTarget as HTMLBdsInputChipsElement;
    const chips = await input?.get();

    // Regex to extract only date values from the input (ex: 01/10 | from 01/10 - Something)
    const regex = /([0-3]\d)\/([01]\d)/g;

    const result = (chips?.toString().match(regex)?.toString() as string) || undefined;
    setHoliday(current => ({ ...current, holidays: result }));
  };

  return {
    workSchedule,
    initHook,
    twentyFourHours,
    setTwentyFourHours,
    sunday,
    setSunday,
    monday,
    setMonday,
    tuesday,
    setTuesday,
    wednesday,
    setWednesday,
    thursday,
    setThursday,
    friday,
    setFriday,
    saturday,
    setSaturday,
    holiday,
    setHoliday,
    saveOpeningHours,
    handleHolidaysChips,
    infoLoaded,
  };
};
